import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { Subscription } from 'rxjs';

import { bl19personOwn, bl19news } from 'src/app/app.bl.types';
import { AuthService } from 'src/app/services/auth.service';

@Component({
  selector: 'bl19-news',
  templateUrl: './news.component.html',
  styleUrls: ['./news.component.scss'],
})
export class NewsComponent implements OnInit {

  newsSelected: boolean = false;
  choosenNews: bl19news = null;

  // for parameters in calling url
  subParam: Subscription;

  subscriptionMe: Subscription;
  me$: bl19personOwn = null;

  addNews: boolean = false;

  inUID: string;


  constructor(
    private route: ActivatedRoute,
    private authServ: AuthService
  ) { }

  ngOnInit(): void {
    this.subParam = this.route.params.subscribe(params => {
      this.inUID = this.route.snapshot.queryParams['uid'] || 'empty';

      if (this.inUID != 'empty') {
        this.newsSelected = true;
      }
    });

    this.subscriptionMe = this.authServ.getMeObs().subscribe(user => {
      if (user) {
        this.me$ = user;
      }
    });
  }


  ngOnDestroy(): void {
    //Called once, before the instance is destroyed.
    //Add 'implements OnDestroy' to the class.

    if (this.subscriptionMe) {
      this.subscriptionMe.unsubscribe();
    }
  }

  chooseNews(chooseNews: bl19news) {
    this.newsSelected = true;
    this.inUID = 'empty';
    this.choosenNews = chooseNews;
  }
}