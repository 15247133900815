<div class="bl19DarkerBackground">
    <bl19-toolbar [transparent]="true"></bl19-toolbar>

    <!-- if no news has been selected from list, or through url, show carousel -->
    <div *ngIf="!newsSelected">
        <bl19-news-carousel></bl19-news-carousel>
    </div>

    <!-- else: show this news -->
    <div *ngIf="newsSelected">
        <bl19-news-single [news]="choosenNews" [uid]="inUID"></bl19-news-single>
    </div>

    <div class="layoutColumn">
        <div *ngIf="me$ && me$.rights >= 500" style="padding: 30px; margin: auto">
            <button mat-stroked-button *ngIf="!addNews" (click)="addNews = true">
                <mat-icon aria-label="Plus Symbol">
                    add
                </mat-icon>
                <h2>News hinzufügen</h2>
            </button>
    
            <div *ngIf="addNews">
                <bl19-news-single-edit (editEmitter)="addNews = $event">
                </bl19-news-single-edit>
            </div>
        </div>
    </div>



    <mat-tab-group mat-align-tabs="center">
        <mat-tab label="News">
            <bl19-news-list [inNewsQuery]='{parameter:"regularEvents", value:"" }'
                (newsEmitter)="chooseNews($event)"></bl19-news-list>
        </mat-tab>
        <mat-tab label="Termine">
            <bl19-news-list [inNewsQuery]='{parameter:"regularEvents", value:true }' [numberOfNews]=15
                (newsEmitter)="chooseNews($event)">
            </bl19-news-list>
        </mat-tab>
    </mat-tab-group>

</div>