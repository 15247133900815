import { Injectable } from '@angular/core';

import { AngularFirestore, AngularFirestoreCollection, AngularFirestoreDocument } from '@angular/fire/compat/firestore'


import { bl19personSmall, bl19group, bl19EventInfo, bl19personOwn } from 'src/app/app.bl.types';



@Injectable({
  providedIn: 'root'
})
export class GroupsService {

  AllInternGroups: Array<bl19group> = [];

  constructor(
    private afs: AngularFirestore,
  ) { }


  getAllInternGroups(): Promise<Array<bl19group> | any> {
    return new Promise<Array<bl19group> | any>((resolve, reject) => {

      // if groups have not been downloaded yet:
      if (this.AllInternGroups.length < 1) {
        const collGroups = this.afs.collection<bl19group>('users/u_groups/intern');

        collGroups.get().toPromise().then(res => {
          if (res.docs.length > 0) {
            res.docs.forEach(doc => {
              this.AllInternGroups.push(doc.data());
            });
            resolve(this.AllInternGroups);
          } else {
            console.error('groupsService: Error on downloading All groups - seems there is no data in this path ');
          }
        }, err => {
          console.error('groupsService: Error on downloading All groups: ' + err);
        });
      } else {

        // groups allready downloaded, so just return saved value
        resolve(this.AllInternGroups);
      }
    });
  }

  getUsersInGroup(uid: string): Promise<Array<bl19personSmall> | any> {

    return new Promise<Array<bl19personSmall> | any>((resolve, reject) => {
      const collGroupMembers = this.afs.collection<bl19personSmall>('users/u_groups/intern/' + uid + '/user');

      let groupMembers: Array<bl19personSmall> = [];

      collGroupMembers.get().toPromise().then(res => {
        if (res.docs.length > 0) {
          res.docs.forEach(doc => {
            groupMembers.push(doc.data());
          });
          resolve(groupMembers);
        } else {
          console.error('groupsService: Error on downloading All members of a group ' + uid + '- seems there is no data in this path');
        }
      }, err => {
        console.error('groupsService: Error on downloading All members of the group: ' + uid + "  ---  " + err);
      });
    });
  }

  removeUserFromGroup(uid: string, user: bl19personSmall): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      const refUser = this.afs.doc<bl19personSmall>('users/u_groups/intern/' + uid + '/user/' + user.uid);

      refUser.delete().then(res => {
        console.log("GroupService: User: " + user.name + " deleted successfully from group: " + uid);
        resolve("GroupService: User: " + user.name + " deleted successfully from group: " + uid);
      }, err => {
        console.error("GroupService: Not successfull on deleting User: " + user.name + " from group: " + uid);
        reject("GroupService: Not successfull on deleting User: " + user.name + " from group: " + uid);
      });
    });
  }


  addUserToGroup(user: bl19personOwn, uid: string): Promise<any> {
    return new Promise<any>((resolve, reject) => {

      // #################################################################
      // get data from bl19personOwn Object and copy it to bl19personSmall
      let userSmall: bl19personSmall = {
        uid: user.UID,
        name: user.name,
      }
      if (user.thumb_picture) {
        userSmall.thumb_picture = user.thumb_picture;
      }
      if (user.picture) {
        userSmall.picture = user.picture;
      }
      //


      // #################################################################
      // copy data to Firestore
      const refNewMember = this.afs.doc<bl19personSmall>('users/u_groups/intern/' + uid + '/user/' + user.UID);

      refNewMember.set(userSmall).then(res => {
        console.log("GroupsService: User " + user.name + " added successfull to group: " + uid);
        resolve("User " + user.name + " added successfull to group: " + uid);
      }, err => {
        console.error("GroupsService: User " + user.name + " added successfull to group: " + uid + " error: " + err);
        reject("User " + user.name + " NOT added successfull to group: " + uid);
      });
    });
  }


  setGroupData(group: bl19group, uid?: string): Promise<any> {
    return new Promise<any>((resolve, reject) => {

      // if there is a group unique Identifier
      // we have to update an existing group
      if (uid) {
        const docNews = this.afs.doc<bl19group>('users/u_groups/intern/' + uid);
        docNews.update(group).then(res => {
          console.log('GroupService: successfully updated Group: ' + uid);
          resolve(res);
        }, err => {
          console.error('GroupService: Error on updating Group with UID: ' + uid + ' error: ' + err);
          reject(err);
        });
      } else {
        // if there is no group unique Identifier
        // we have to create a new group
        const collNews = this.afs.collection<bl19group>('users/u_groups/intern');
        collNews.add(group).then(res => {
          console.log('GroupsService: Successfully added new Group: ' + res.id);

          // now download the new uploaded doc:
          const docNews = this.afs.doc<bl19group>('users/u_groups/intern/' + res.id);
          docNews.get().toPromise().then(res => {
            let result: bl19group = res.data();

            // add the id as sui and then send this back to the caller
            result.uid = res.id;
            resolve(result);
          }, err => {
            console.error('GroupsService: Error on downloading most recent added Group: ' + err);
          });
        }, err => {
          console.error('GroupsService: Error on adding new Group: ' + err);
          reject(err);
        });
      }
    });

  }

}
