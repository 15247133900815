
<div class="wrapper">
  <mat-accordion multi="true">

    <!-- map and address expansion box -->
    <mat-expansion-panel [expanded]=true>
      <mat-expansion-panel-header>
        <mat-panel-title>
          <h2>Hier findest du uns:</h2>
        </mat-panel-title>
      </mat-expansion-panel-header>
      
      <ng-template matExpansionPanelContent>
        <div class="container">
          <div class="map">
            <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2645.9042645461186!2d10.27685155156928!3d48.45836637914857!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x479945099c0d3779%3A0x2d1d0c2a925722e8!2sBibelgemeinde%20G%C3%BCnzburg!5e0!3m2!1sde!2sde!4v1585934108361!5m2!1sde!2sde" width="600" height="450" frameborder="0" style="border:0;" allowfullscreen="" aria-hidden="false" tabindex="0"></iframe>
          </div>
          <div class="address">
            <h2>Anschrift:</h2>
            <p>Evangeliums Christen Gemeinde Günzburg e.V.</p>
            <p>Maria-Theresia-Str. 2</p>
            <p>D-89312 Günzburg</p>
          </div>
        </div>
      </ng-template>
    </mat-expansion-panel>

    <!-- contact form-->
    <mat-expansion-panel>
      <mat-expansion-panel-header>
        <mat-panel-title>
          <h2>Kontakt aufnehmen</h2>
        </mat-panel-title>
      </mat-expansion-panel-header>
      
      <ng-template matExpansionPanelContent>
        <div class="container">
            Todo
        </div>
      </ng-template>
    </mat-expansion-panel>

  </mat-accordion>
</div>