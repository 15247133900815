<div *ngIf="!groupList" class="blcenterelement">
    <mat-spinner></mat-spinner>
</div>

<div *ngIf="me$ && me$.rights >= 1000" class="layoutRow">
    <button mat-fab color="primary" *ngIf="!addGroup" (click)="addGroup = true">
        <mat-icon aria-label="Plus Symbol">
            add
        </mat-icon>
    </button>
    <h3 *ngIf="!addGroup" style="margin-left: 5px;">neue Gruppe...</h3>

    <div *ngIf="addGroup">
        <bl19-group-edit (editEmitter)="addGroup = $event"></bl19-group-edit>
    </div>

</div>

<div *ngIf="groupList">
    <mat-divider style="margin: 20px;"></mat-divider>

    <div *ngFor="let group of groupList">

        <bl19-group-single [group]="group"></bl19-group-single>

        <mat-divider style="margin: 20px;"></mat-divider>
    </div>
</div>