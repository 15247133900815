<div class="layoutColumn">

    <div class="dropZone" bl19DropZone (hovered)="toggleHover($event)" (dropped)="startUpload($event)"
        [class.hovering]="isHovering" style="text-align: center;">

        <h2>
            <mat-icon aria-hidden="false" aria-label="upload icon">cloud_upload</mat-icon>
        </h2>
        <h3> Ziehe die Datei hier her, oder... </h3>

        <input style="display:none;" #fileInput class="file-input" type="file" accept=".mp3"
            (change)="startUpload($event.target.files)">

        <button (click)="triggerFileInput()" mat-raised-button color="primary">
            ... Datei wählen
        </button>

    </div>



    <div *ngIf="percentage | async as pct">
        <mat-progress-bar mode="determinate" [value]="pct"></mat-progress-bar>
        {{ pct | number }}%
    </div>




    <div *ngIf="(snapshot | async) as snap">
        {{ snap.bytesTransferred | fileSize }} of {{ snap.totalBytes | fileSize }}

        <hr>

        <button (click)="task.pause()" mat-raised-button color="accent" [disabled]="!isActive(snap)">Pause</button>
        <button (click)="task.cancel()" mat-raised-button color="warn" [disabled]="!isActive(snap)">Cancel</button>
        <button (click)="task.resume()" mat-raised-button color="primary"
            [disabled]="!(snap?.state === 'paused')">Resume</button>

    </div>