<bl19-toolbar></bl19-toolbar>

<div style="padding-top: 90px;" class="container mat-typography" id="about-heading">
    <h2>
        Über uns
    </h2>
    <mat-divider id="about-divider"></mat-divider>
</div>

<div id="about-content">
  <bl19-information></bl19-information>
</div>
