<h2 style="text-align: center">
    Rechte:
</h2>

<div class="layoutColumn">


    <mat-form-field>
        <mat-label>
            {{ beginRights.level }}
            {{ beginRights.description }}
        </mat-label>
        <mat-select (selectionChange)="selected($event.value)">
            <mat-option *ngFor="let right of rightsList" [value]="right.level">
                {{ right.level }}
                {{ right.description }}
            </mat-option>
        </mat-select>
    </mat-form-field>

    <div *ngIf="changesMade" style="padding-bottom: 20px">
        <!--  Save -->
        <button (click)="setRights()" mat-button>
            <mat-icon aria-hidden="false" aria-label="done icon">done</mat-icon>
            Rechte Setzen
        </button>
    </div>

</div>