<div style="min-width: 300px;">
    <h3 style="margin-bottom: 1px; margin-top: 25px;">{{ sermon.title }}</h3>

    <div class="layoutRow">

        <div style="padding-left: 10px;">
            <p style="margin-top: 0px;">
                {{ sermon.date | date }} <br>
                {{ sermon.type }} <br>
                {{ sermon.book }} {{ chapterVerse }} <br>
                {{ sermon.preacherName }}
            </p>
        </div>

        <img *ngIf="sermon.preacherObject && sermon.preacherObject.thumb_picture"
            src={{sermon.preacherObject.thumb_picture}} alt="Bild zur Predigt"
            style="height: 50px; border-radius: 50%;">

    </div>
</div>