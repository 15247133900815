<div *ngIf="!newsList" class="blcenterelement">
    <mat-spinner></mat-spinner>
</div>


<div class="container">
    <mat-card class="blitem" *ngFor="let news of newsList" (click)="goToNews(news)" [routerLink]="'/news'"
    [queryParams]="{uid: news.uid}">
        <mat-card-header>
            <h1>{{ news.heading }}</h1>
        </mat-card-header>

        <img mat-card-image src="{{ news.picture }}">

        <mat-card-content>
            <p>{{ news.text }}</p>
        </mat-card-content>
    </mat-card>
</div>

<div *ngIf="noMoreNews" class="container" style="padding-bottom: 30px;">
    <button mat-button (click)="loadMoreSermons()" color="primary" style="width: 100%;">
        <p>mehr...</p>
        <mat-icon aria-label="more Sermons Symbol">
            keyboard_arrow_down
        </mat-icon>
    </button>
</div>