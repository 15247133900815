import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { take, map, tap } from 'rxjs/operators';




import { AuthService } from "../services/auth.service";

@Injectable({
  providedIn: 'root'
})

export class AuthGuard  {

  constructor(
    private router: Router,
    private authServ: AuthService
  ) { }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {


    // if currentUser is allready filled with a value -> return TRUE very fast
    const currentUser = this.authServ.getUser();
    if (currentUser) {
      console.log("AuthGuard: user logged in.... routing to requested page");
      return true
    }


    return this.authServ.getUserObs.pipe(
      take(1),
      map(user => !!user),
      tap(
        loggedIn => {
          if (!loggedIn) {
            console.log("AuthGuard: no authentication... redirecting to login");
            this.router.navigate(['/login'], { queryParams: { retUrl: state.url } });
            return false;
            //return this.router.parseUrl('login');
            //return this.router.createUrlTree(['/login'], { queryParams: { retUrl: state.url } });
          }
          // here we could put an else, and check for further user infos
        }
      )
    );
  }
}

