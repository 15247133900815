<div *ngIf="!sermon" class="blcenterelement">
    <mat-spinner></mat-spinner>
</div>


<div *ngIf="sermon">
    <div *ngIf="!editMode">
        <bl19-sermon-single-data [sermon]="sermon" (editEmitter)="editMode = $event"></bl19-sermon-single-data>
    </div>


    <div *ngIf="editMode">
        <bl19-sermon-single-edit [sermon]="sermon" (editEmitter)="editMode = $event"></bl19-sermon-single-edit>
    </div>
</div>