import { Component, OnInit, Input } from '@angular/core';

import { bl19sermonData } from 'src/app/app.bl.types';
import { SermonService } from 'src/app/services/sermon.service';


@Component({
  selector: 'bl19-sermon-list-element',
  templateUrl: './sermon-list-element.component.html',
  styleUrls: ['./sermon-list-element.component.scss']
})
export class SermonListElementComponent implements OnInit {

  @Input() sermon: bl19sermonData;

  chapterVerse: string = null;

  constructor() { }

  ngOnInit() {
    this.buildChapterVerse();
  }


  buildChapterVerse() {
    if (this.sermon.chapterStart) {
      this.chapterVerse = this.sermon.chapterStart.toString();

      if (this.sermon.verseStart) {
        this.chapterVerse = this.chapterVerse + ";" + this.sermon.verseStart.toString();

        if (this.sermon.chapterEnd && (this.sermon.chapterEnd != this.sermon.chapterStart)) {
          this.chapterVerse = this.chapterVerse + " bis " + this.sermon.chapterEnd.toString() + "; ";
        } else {
          this.chapterVerse = this.chapterVerse + "-"
        }


        if (this.sermon.verseEnd) {
          this.chapterVerse = this.chapterVerse + this.sermon.verseEnd.toString();
        }
      }
    }
  }
}
