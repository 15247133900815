import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';

import { AuthService } from '../../../services/auth.service';
import { bl19personOwn } from '../../../app.bl.types';

@Component({
  selector: 'app-user',
  templateUrl: './user.component.html',
  styleUrls: ['./user.component.scss']
})


export class UserComponent implements OnInit, OnDestroy {

  @Input() person: bl19personOwn;

  meSub: Subscription;
  me: bl19personOwn;

  edit: boolean = false;

  constructor(private authServ: AuthService) { }


  ngOnInit() {
    this.meSub = this.authServ.getMeObs().subscribe(me => {
      this.me = me;
    });
  }

  ngOnDestroy(): void {
    //Called once, before the instance is destroyed.
    //Add 'implements OnDestroy' to the class.
    if (this.meSub) {
      this.meSub.unsubscribe();
    }
  }


  personDataAction(action: string) {
    switch (action) {
      case 'editModeOn':
        this.edit = true;
        break;

      case 'editModeOff':
        this.edit = false;
        break;

      default:
        break;
    }
  }

}
