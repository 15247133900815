import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';

import { MatDialog as MatDialog, MatDialogRef as MatDialogRef, MAT_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/dialog';

import { bl19news, bl19dataRichText } from 'src/app/app.bl.types';
import { UploadFileComponent } from 'src/app/dialogs/upload-file/upload-file.component';
import { Subscription } from 'rxjs';
import { UploadPhotoComponent } from 'src/app/dialogs/upload-photo/upload-photo.component';
import { NewsService } from 'src/app/services/news.service';


@Component({
  selector: 'bl19-news-single-edit',
  templateUrl: './news-single-edit.component.html',
  styleUrls: ['./news-single-edit.component.scss']
})
export class NewsSingleEditComponent implements OnInit {

  @Input() news?: bl19news;

  @Output() editEmitter = new EventEmitter<boolean>();



  subOnChange: Subscription;
  changesMade: boolean = false;

  editNewsForm: UntypedFormGroup;
  editNewsData: bl19news = {};

  richTextObject: bl19dataRichText = {
    richText: ''
  }

  blankNewsData: bl19news = {
    heading: "Titel der News",
    text: "Text der News",

    showOnWelcomePage: false,

    regularEvents: false,

    date: new Date(1986, 10, 19),
  }

  constructor(
    private formB: UntypedFormBuilder,
    private newsServ: NewsService,
    private dialog: MatDialog,
  ) { }


  richTextChange(eventy: any) {
    this.changesMade = true;
  }


  ngOnInit(): void {

    this.editNewsForm = this.formB.group({
      showOnWelcomePage: ['', []],

      regularEvents: ['', []],

      heading: ['', []],        // heading of news
      text: ['', []],

      validUntil: ['', []],

    });

    if (this.news) {
      this.editNewsData = this.news;

      this.newsServ.getSingleNewsRichText(this.news.uid).then(res => {
        if (res.richText) {
          this.richTextObject = res;
        }
      }, err => {
        console.error('Error on downloading News - RichText: ' + err);
      });

      if (this.news.showOnWelcomePage) {
        this.f.showOnWelcomePage.setValue(this.editNewsData.showOnWelcomePage);
      }
      if (this.news.regularEvents) {
        this.f.regularEvents.setValue(this.editNewsData.regularEvents);
      }
    } else {
      this.editNewsData = this.blankNewsData;
    }

    this.onChanges();

  }



  ngOnDestroy(): void {
    this.subOnChange.unsubscribe();
  }

  onChanges(): void {
    this.subOnChange = this.editNewsForm.valueChanges.subscribe(val => {
      console.log("change detected....");
      this.changesMade = true;
    });
  }

  // for easy access to form:
  get f() { return this.editNewsForm.controls }

  editNews() {

    if (this.f.showOnWelcomePage.value == true || this.f.showOnWelcomePage.value == false) {
      this.editNewsData.showOnWelcomePage = this.f.showOnWelcomePage.value;
    }

    if (this.f.regularEvents.value == true || this.f.regularEvents.value == false) {
      this.editNewsData.regularEvents = this.f.regularEvents.value;
    }

    if (this.f.heading.value != "") {
      this.editNewsData.heading = this.f.heading.value;
    }

    if (this.f.text.value != "") {
      this.editNewsData.text = this.f.text.value;
    }

    if (this.f.validUntil.value != "") {
      this.editNewsData.validUntil = this.f.validUntil.value;
    }


    if (this.changesMade) {

      this.changesMade = false;

      // check if we want to change an existing News
      if (this.news) {
        this.newsServ.updateNews(this.editNewsData, this.news.uid).then(res => {
          console.log("NewsSingleEdit: Update erfolgreich.")

          // if news Update has been successfull, update richText
          this.newsServ.updateSingleNewsRichText(this.news.uid, this.richTextObject).then(res => {
            // update successfull
            // do nothing
            console.log('News RichText updated: ' + res);
          }, err => {
            console.error("Error on updating NewsRichText-Object: " + err);
          });


        }, err => console.log(err));
      } else {
        // if we dont want to change an existing news, we have to create a new one:
        this.newsServ.updateNews(this.editNewsData).then(res => {

          // a new News has been created,
          // the uid has been set - on the next update we have to write the uid:
          this.news = res;

          this.newsServ.updateSingleNewsRichText(this.news.uid, this.richTextObject).then(res => {
            // update successfull
            // do nothing
            console.log('News RichText updated: ' + res);
          }, err => {
            console.error("Error on updating NewsRichText-Object: " + err);
          });

        }, err => console.log(err));
      }
    }
  }


  dialogUploadPhoto(): void {

    const pathToPut = 'web/content/news/' + this.news.uid + '/';
    const fileName = 'newsPicture';

    const dialogRef = this.dialog.open(UploadPhotoComponent, {
      width: '95%',
      height: '100 vh',
      data: { path: pathToPut, fileName: fileName, type: "image" }
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log(result);
      //this.downloadPhotoUrlReady(result.url, result.file);
    });

  }


  deleteNews() {

    if (this.news.uid) {
      this.newsServ.deleteNews(this.news.uid).then(() => {
        this.editEmitter.emit(false);
      });
    } else {
      this.editEmitter.emit(false);
    }
    this.editEmitter.emit(false);
  }

  closeEdit() {
    this.editEmitter.emit(false);
  }

}
