import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import { Router } from '@angular/router';


import { UiServiceService } from 'src/app/services/ui-service.service';
import { AuthService } from 'src/app/services/auth.service';

import { bl19personOwn } from 'src/app/app.bl.types';


@Component({
  selector: 'bl19-toolbar',
  templateUrl: './toolbar.component.html',
  styleUrls: ['./toolbar.component.scss']
})
export class ToolbarComponent implements OnInit, OnDestroy {


  @Input() transparent: boolean = false;

  loadingSub: Subscription;
  loading: boolean = false;

  subscriptionMe: Subscription;
  me$: bl19personOwn = null;

  constructor(private ui: UiServiceService,
    private authServ: AuthService,
    private router: Router) {
  }

  ngOnInit() {
    if (this.transparent) {
      window.addEventListener('scroll', this.scroll, true);
    }
    this.loadingSub = this.ui.getLoadingObs().subscribe(value => {
      this.loading = value;
    });


    this.subscriptionMe = this.authServ.getMeObs().subscribe(user => {
      if (user) {
        this.me$ = user;
      }
    });
  }

  ngOnDestroy(): void {
    //Called once, before the instance is destroyed.
    //Add 'implements OnDestroy' to the class.
    window.removeEventListener('scroll', this.scroll, true);

    this.loadingSub.unsubscribe();


    if (this.subscriptionMe) {
      this.subscriptionMe.unsubscribe();
    }
  }

  scroll = (): void => {
    const number = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0;
    if (number > 300) {
      this.transparent = false;
    } else if (number < 300) {
      this.transparent = true;
    }
  }


  callLogout() {
    this.authServ.doLogout().then(res => {
      this.router.navigate(['/home']);
    }, err => console.log('Error on Logout: ' + err));
  }


}
