import { Directive, HostListener, HostBinding, Output, EventEmitter } from '@angular/core';

@Directive({
  selector: '[bl19DropZone]'
})
export class DropZoneDirective {

  @Output() dropped = new EventEmitter<File>();
  @Output() hovered = new EventEmitter<boolean>();

  constructor() { }

  @HostListener('drop', ['$event'])
  onDrop($event) {
    $event.preventDefault();
    $event.stopPropagation();

    // check if it is only ONE file
    if (($event.dataTransfer.files.length > 0) && ($event.dataTransfer.files.length < 2)) {


      // check if file  is AUDIO or IMAGE
      const splitType = $event.dataTransfer.files[0].type.split('/');
      if (splitType[0] === 'image' || splitType[0] === 'audio') {
        this.dropped.emit($event.dataTransfer.files);
      } else {
        console.error('dropZone directive:  only audio or images supported');
      }



    } else {
      console.error('dropZone directive:  no files or more than one file dropped');
      console.log($event.dataTransfer.files)
    }
    this.hovered.emit(false);
  }


  @HostListener('dragover', ['$event'])
  onDragOver($event) {
    $event.preventDefault();
    $event.stopPropagation();
    this.hovered.emit(true);
  }

  @HostListener('dragleave', ['$event'])
  onDragLeave($event) {
    $event.preventDefault();
    $event.stopPropagation();
    this.hovered.emit(false);
  }


}
