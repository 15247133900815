import { Component, OnInit, Input } from '@angular/core';

import { bl19group } from 'src/app/app.bl.types';

@Component({
  selector: 'bl19-group-single',
  templateUrl: './group-single.component.html',
  styleUrls: ['./group-single.component.scss']
})
export class GroupSingleComponent implements OnInit {

  @Input() group: bl19group;

  editMode: boolean = false;

  constructor() { }

  ngOnInit() {
  }

}
