<h2 mat-dialog-title>Auswahl:</h2>

<mat-dialog-content>

    <div *ngIf="!members" class="blcenterelement">
        <mat-spinner></mat-spinner>
    </div>

    <div *ngIf="members">
        <mat-list>
            <mat-list-item *ngFor="let member of members">
                <p matLine>
                    <span> {{ member.name }} </span>
                    <button mat-button color="primary">
                        <mat-icon aria-label="Plus Symbol" (click)="addMember(member)">
                            add
                        </mat-icon>
                    </button>
                </p>
            </mat-list-item>
        </mat-list>
    </div>
</mat-dialog-content>


<mat-dialog-actions>
    <button class="mat-raised-button" (click)="close()">Close</button>
    <button class="mat-raised-button mat-primary" (click)="save()">Save</button>
</mat-dialog-actions>