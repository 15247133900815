import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';

import { bl19group, bl19personOwn, bl19personSmall } from 'src/app/app.bl.types';
import { GroupsService } from 'src/app/services/groups.service';


@Component({
  selector: 'bl19-group-edit',
  templateUrl: './group-edit.component.html',
  styleUrls: ['./group-edit.component.scss']
})
export class GroupEditComponent implements OnInit {

  @Input() group?: bl19group = null;
  @Output() editEmitter = new EventEmitter<boolean>();

  newGroup: boolean = true;

  editGroupForm: UntypedFormGroup;
  editGroupData: bl19group = {};


  blankGroupData: bl19group = {
    name: "Name der Gruppe",
    description: "Beschreibung der Gruppe..."
  }

  constructor(private formB: UntypedFormBuilder,
    private groupServ: GroupsService) { }

  // for easy access to form:
  get f() { return this.editGroupForm.controls }

  ngOnInit() {

    this.editGroupForm = this.formB.group({
      name: ['', []],
      description: ['', []]
    });


    if (this.group) {

      this.editGroupData = this.group;
      this.newGroup = false;
      this.f.name.disable();

    } else {
      this.editGroupData = this.blankGroupData;
      console.log(this.editGroupData);
    }


  }


  editGroup() {
    var changed: boolean = false;

    if (this.f.name.value != "") {
      this.editGroupData.name = this.f.name.value;
      changed = true;
    }
    if (this.f.description.value != "") {
      this.editGroupData.description = this.f.description.value;
      changed = true;
    }


    if (changed) {
      if (this.group) {
        this.groupServ.setGroupData(this.editGroupData, this.group.uid).then(res => this.editEmitter.emit(false));
      } else {
        this.groupServ.setGroupData(this.editGroupData).then(res => this.editEmitter.emit(false));
      }
    }
  }

  closeEdit() {
    this.editEmitter.emit(false);
  }
}
