import { Pipe, PipeTransform, SecurityContext } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';

@Pipe({
  name: 'safe'
})
export class SafePipe implements PipeTransform {

  constructor(private sanitizer: DomSanitizer) {
  }

  transform(url: string): SafeHtml {
    if (url.includes('https://www.youtube-nocookie.com/embed/')) {
      return this.sanitizer.bypassSecurityTrustResourceUrl(url);
    } else {
      return 'error, Bad URL';
    }

  }
}
