import { Component, OnInit } from '@angular/core';

import { EventsService } from 'src/app/services/events.service';



@Component({
  selector: 'bl19-events',
  templateUrl: './events.component.html',
  styleUrls: ['./events.component.scss']
})
export class EventsComponent implements OnInit {

  constructor(private evServ: EventsService) { }

  nextEvents: Array<any> = [];


  datesWithEvents: Array<Date> = [];
  daysWithEvents: Array<number> = [];



  ngOnInit(): void {
    this.evServ.getPlannedEvents().then(res => {
      this.nextEvents = res;

      // open an array for a day:
      let eventDay: Array<any> = [];


      // fill the eventDay with all corresponding events:
      this.nextEvents.forEach(element => {
        let day: number = element.startDate.getDate();
        if (!(this.daysWithEvents.indexOf(day) > -1)) {
          this.datesWithEvents.push(element.startDate);
          this.daysWithEvents.push(day);
        };
      });

    }, err => console.log("Events can not be shown..."));
  }
}
