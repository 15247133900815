<form [formGroup]="loginForm" >

    <!-- Email -->
    <mat-form-field>
        <input matInput placeholder="EMail" formControlName="email">
        <div *ngIf="f.email.errors && (f.email.dirty || f.email.touched)">
            <mat-error *ngIf="f.email.errors.required">
                Eine Email-Adresse wird <strong>benötigt</strong>
            </mat-error>
            <mat-error *ngIf="!f.email.errors.required">
                Bitte gebe eine gültige EMail-Adresse an!
            </mat-error>
        </div>
    </mat-form-field>


    <!-- Passwort -->
    <mat-form-field style="padding-bottom: 50px">
        <input matInput placeholder="Passwort" formControlName="pw" type="password">
        <div *ngIf="f.pw.errors && (f.pw.dirty || f.pw.touched)">
            <mat-error *ngIf="f.pw.errors.required">
                Ein Passwort wird <strong>benötigt</strong>
            </mat-error>
        </div>
    </mat-form-field>


    <!-- register button -->
    <div>
        <span fxFlex="auto"></span>
        <button (click)="login()" mat-raised-button [disabled]="(loginForm.invalid)">Login</button>
        <span fxFlex="auto"></span>
    </div>


</form>