import { Component, OnInit, Input, Output, EventEmitter, OnDestroy } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';

import { MatDialog } from '@angular/material/dialog';

import { bl19sermonData, bl19BibleBooks, bl19personSmall, bl19typeSermon } from 'src/app/app.bl.types';
import { SermonService } from 'src/app/services/sermon.service';
import { UploadFileComponent } from 'src/app/dialogs/upload-file/upload-file.component';
import { Subscription } from 'rxjs';
import { UploadPhotoComponent } from 'src/app/dialogs/upload-photo/upload-photo.component';


@Component({
  selector: 'bl19-sermon-single-edit',
  templateUrl: './sermon-single-edit.component.html',
  styleUrls: ['./sermon-single-edit.component.scss']
})



export class SermonSingleEditComponent implements OnInit, OnDestroy {

  @Input() sermon?: bl19sermonData;

  @Output() editEmitter = new EventEmitter<boolean>();

  subOnChange: Subscription;
  changesMade: boolean = false;

  editSermonForm: UntypedFormGroup;
  editSermonData: bl19sermonData = {};


  blankSermonData: bl19sermonData = {
    title: "Titel der Predigt",
    date: new Date(1986, 10, 19),

    guestPreacher: false,
    preacherName: "Gastprediger",
    showOnWelcomePage: true,

    chapterStart: 0,
    chapterEnd: 0,
    verseStart: 0,
    verseEnd: 0,

    type: 'Gottesdienst'
  }



  // variables for data
  bibleBooks: string[] = bl19BibleBooks;
  preachers: Array<bl19personSmall> = [];
  sermonTypes: string[] = bl19typeSermon;

  constructor(private formB: UntypedFormBuilder,
    private sermServ: SermonService,
    private dialog: MatDialog) { }

  ngOnInit() {

    this.editSermonForm = this.formB.group({
      title: ['', []],        // title of sermon
      date: ['', []],         // date of sermon
      book: ['', []],         // book of sermon

      guestPrForm: ['', []],      // is it a guest preacher
      preacherName: ['', []],     // name of guest preacher
      preacherObject: ['', []],   // preacher structure

      showOnWelcomePage: ['', []],  // if sermon has to be shown on langing page

      chStart: ['', []],      // Chapter Start
      chEnd: ['', []],        // Chapter End
      verseStart: ['', []],   // Verse Start
      verseEnd: ['', []],     // Verse End

      videoLink: ['', []],    // video-Link-Identifier

      type: ['', []],         // type of sermon (Gottesdienst, Jugend, Bibelstunde, GBS, usw...)
    });


    // fill initial Values, if no data has been given
    if (this.sermon) {
      this.editSermonData = this.sermon;
    } else {
      this.editSermonData = this.blankSermonData;
    }

    // because checkbox has no better way to change the data after initialization of Component
    this.f.guestPrForm.setValue(this.editSermonData.guestPreacher);

    this.sermServ.getPreachers().then(res => {
      this.preachers = res;
    }, err => console.error(err));

    // because checkbox has no better way to change the data after initialization of Component
    this.f.showOnWelcomePage.setValue(this.editSermonData.showOnWelcomePage);


    // register subscription for changes in Form
    this.onChanges();
  }


  ngOnDestroy(): void {
    this.subOnChange.unsubscribe();
  }



  onChanges(): void {
    this.subOnChange = this.editSermonForm.valueChanges.subscribe(val => {
      this.changesMade = true;
    });
  }


  dialogUploadFile(): void {

    const pathToPut = 'web/content/sermon/' + this.sermon.suid + '/'

    const dialogRef = this.dialog.open(UploadFileComponent, {
      width: '300px',
      data: { path: pathToPut, fileName: 'sermonPicuture.png', type: "mp3" }
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log(result);
      this.downloadMp3UrlReady(result.url, result.file, result.fileSize, result.duration);
    });

  }


  dialogUploadPhoto(): void {

    const pathToPut = 'web/content/sermon/' + this.sermon.suid + '/';
    const fileName = 'sermonPicture';

    const dialogRef = this.dialog.open(UploadPhotoComponent, {
      width: '95%',
      height: '100 vh',
      data: { path: pathToPut, fileName: fileName, type: "image" }
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log(result);
      this.downloadPhotoUrlReady(result.url, result.file);
    });

  }


  // for easy access to form:
  get f() { return this.editSermonForm.controls }


  editSermon() {

    if (this.f.title.value != "") {
      this.editSermonData.title = this.f.title.value;
    }

    if (this.f.showOnWelcomePage.value == true || this.f.showOnWelcomePage.value == false) {
      this.editSermonData.showOnWelcomePage = this.f.showOnWelcomePage.value;
    }

    if (this.f.date.value != "") {
      this.editSermonData.date = this.f.date.value;
    }

    if (this.f.book.value != "") {
      this.editSermonData.book = this.f.book.value;
    }
    //----
    if (this.f.chStart.value != "") {
      this.editSermonData.chapterStart = this.f.chStart.value;
    }

    if (this.f.chEnd.value != "") {
      this.editSermonData.chapterEnd = this.f.chEnd.value;
    }

    if (this.f.verseStart.value != "") {
      this.editSermonData.verseStart = this.f.verseStart.value;
    }

    if (this.f.verseEnd.value != "") {
      this.editSermonData.verseEnd = this.f.verseEnd.value;
    }

    if (this.f.videoLink.value && (this.f.videoLink.value != "")) {

      // need a link like that: https://www.youtube-nocookie.com/embed/XT1hJQI5BWI
      // got a code like that: XT1hJQI5BWI
      this.editSermonData.videoLink = "https://www.youtube-nocookie.com/embed/" + this.f.videoLink.value;
    }

    if (this.f.type.value != "") {
      this.editSermonData.type = this.f.type.value;
    }

    if (this.f.preacherName.value != "") {
      this.editSermonData.preacherName = this.f.preacherName.value;
    }

    if (this.f.preacherObject && !this.editSermonData.guestPreacher && this.f.preacherObject.value != "") {
      this.editSermonData.preacherObject = this.f.preacherObject.value;

      // if preacher bl19personSmall is available, set also name:
      this.editSermonData.preacherName = this.f.preacherObject.value.name;
    }



    if (this.changesMade) {

      this.changesMade = false;

      // check if we want to change an existing sermon
      if (this.sermon) {
        this.sermServ.updateSermon(this.editSermonData, this.sermon.suid).then(res => console.log("SermonSingleEdit: Update erfolgreich."), err => console.log(err));
      } else {
        // if we dont want to change an existing sermon, we have to create a new one:
        this.sermServ.updateSermon(this.editSermonData).then(res => {

          // a new sermon has been created,
          // the suid has been set - on the next update we have to write the suid:
          this.sermon = res;

        }, err => console.log(err));
      }
    }
  }


  downloadMp3UrlReady(url: string, filePath: string, fileSize: number, duration: number) {
    let updateSermonData: bl19sermonData = {
      link: url,
      filePath: filePath,
      fileSize: fileSize,
      duration: duration,
    }

    this.sermServ.updateSermon(updateSermonData, this.sermon.suid).then(res => {

      // write url and filePath to sermon - object to be able to play it
      this.sermon.link = url;
      this.sermon.filePath = filePath;
    }, err => console.error("SermonSingleEdit: error on adding Link to sermon: " + err));
  }


  downloadPhotoUrlReady(url: string, filePath: string) {
    let updateSermonData: bl19sermonData = {
      picture: url,
    }

    this.sermServ.updateSermon(updateSermonData, this.sermon.suid).then(res => {

      // write pictureURL and picturePath to sermon-Object to show it instant
      this.sermon.picture = url;
      this.sermon.picturePath = filePath;
    });
  }


  changeChapter(newStartChapter: number) {
    this.editSermonData.chapterEnd = newStartChapter;
  }

  closeEdit() {
    this.editEmitter.emit(false);
  }

  deleteSermon() {

    if (this.sermon.suid) {
      this.sermServ.deleteSermon(this.sermon.suid).then(() => {
        this.editEmitter.emit(false);
      });
    } else {
      this.editEmitter.emit(false);
    }
    this.editEmitter.emit(false);
  }

}
