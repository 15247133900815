import { Component, OnInit, Output, EventEmitter } from '@angular/core';

import { AuthService } from '../../../../services/auth.service';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';



@Component({
  selector: 'app-mail-register',
  templateUrl: './mail-register.component.html',
  styleUrls: ['./mail-register.component.scss']
})
export class MailRegisterComponent implements OnInit {

  @Output() successEmitter = new EventEmitter();

  registerForm: UntypedFormGroup;

  constructor(private authServ: AuthService,
    private formB: UntypedFormBuilder) { }


  ngOnInit() {
    this.registerForm = this.formB.group({
      email: ['', [Validators.required, Validators.email]],
      pw: ['', [Validators.required, Validators.minLength(6)]],
      confirmPw: ['', [Validators.required, Validators.minLength(6)]],
      dataLaw: ['', [Validators.required]]
    }, { validator: this.checkConfirmPassword });
  }


  // for easy access to form:
  get f() { return this.registerForm.controls };


  // send entered data to register
  register() {
    this.authServ.doRegister(this.f.email.value, this.f.pw.value).then(res => this.successEmitter.emit(), err => { console.log(err) });
  }


  // custom validator
  // to check if password and confirm password match
  checkConfirmPassword(group: UntypedFormGroup) {
    let pw = group.controls.pw.value;
    let confirmPw = group.controls.confirmPw.value;

    return pw === confirmPw ? null : { notSame: true }
  }

}
