<mat-card>
    <form [formGroup]="editNewsForm">

        <div *ngIf="news" style="text-align: center;">
            <h1 *ngIf="news.uid">{{news.uid}}</h1>
        </div>

        <div class="blEditNews" style="text-align: center;">
            <mat-checkbox matInput formControlName="showOnWelcomePage" [checked]="editNewsData.showOnWelcomePage">
                Auf Startseite anzeigen
            </mat-checkbox>
        </div>


        <div class="blEditNews layoutColumn">
            <mat-form-field class="blEditNews bl19inputFild">
                <input matInput formControlName="heading" [placeholder]="editNewsData.heading">
                <mat-hint>Überschrift</mat-hint>
            </mat-form-field>

            <mat-form-field class="blEditNews bl19inputFild">
                <textarea matInput formControlName="text" [placeholder]="editNewsData.text"></textarea>
                <mat-hint>Text der News</mat-hint>
            </mat-form-field>

            <mat-form-field class="blEditNews">
                <input matInput type="date" formControlName="validUntil"
                    [placeholder]="editNewsData.validUntil | date:'d.M.yyyy'">
                <mat-hint>Gültig bis</mat-hint>
            </mat-form-field>

            <ejs-richtexteditor [(value)]='richTextObject.richText' (change)='richTextChange($event)'>
            </ejs-richtexteditor>

        </div>


        <mat-divider></mat-divider>

        <div *ngIf="news && news.uid">
            <button (click)="dialogUploadPhoto()" mat-button style="margin: 30px;">
                <mat-icon aria-hidden="false" aria-label="upload icon">cloud_upload</mat-icon>
                Upload Photo
            </button>
        </div>
        <div *ngIf="news && news.picture">
            <img style="max-width: 60%; margin-bottom: 20px;" src="{{ news.picture }}" alt="News picture">
        </div>


        <div class="blEditNews" style="text-align: center; margin: auto">
            <mat-checkbox matInput formControlName="regularEvents" [checked]="editNewsData.regularEvents">
                Handelt es ich um Informationen zu einer wiederkehrenden Veranstaltung. (z.B Kinderstunde)
            </mat-checkbox>
        </div>

        <mat-divider></mat-divider>



        <!--  Save and Undo -->
        <div class="layoutRow" style="padding: 20px">
            <button (click)="editNews()" mat-button *ngIf="changesMade">
                <mat-icon aria-hidden="false" aria-label="done icon">done</mat-icon>
                Speichern
            </button>
            <button (click)="deleteNews()" mat-button type="button">
                <mat-icon aria-hidden="false" aria-label="delete icon">delete</mat-icon>
                Löschen
            </button>
            <button (click)="closeEdit()" mat-button type="button">
                <mat-icon aria-hidden="false" aria-label="delete icon">clear</mat-icon>
                Schließen
            </button>
        </div>


    </form>
</mat-card>