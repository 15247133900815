<div class="bl19LightBackground">
    <bl19-toolbar [transparent]="true"></bl19-toolbar>



    <div *ngIf="inSermon || (inSUID && inSUID!='empty')">
        <bl19-sermon-single [suid]="inSUID" [sermon]="inSermon">
        </bl19-sermon-single>
    </div>

    <div *ngIf="me$ && me$.rights >= 500" style="padding-bottom: 30px; margin-top: 50px;" class="layoutRow">
        <button mat-stroked-button *ngIf="!addSermon" (click)="addSermon = true">
            <mat-icon aria-label="Plus Symbol">
                add
            </mat-icon>
            <h2>Predigt hinzufügen</h2>
        </button>

        <div *ngIf="addSermon" class="layoutRow">
            <bl19-sermon-single-edit (editEmitter)="addSermon = $event"></bl19-sermon-single-edit>
        </div>
    </div>

    <div style="margin-bottom: 50px;">
        <bl19-sermon-list [sermonSite]='true' [limit]='4' [sermonType]="'Gottesdienst'"
            (sermonEmitter)="newSermon($event)" (latestSermonEmitter)="latestSermon($event)"></bl19-sermon-list>
    </div>

</div>