<div>
    <ng-template #loading>
        <div class="blcenterelement">
            <mat-spinner></mat-spinner>
        </div>
    </ng-template>
</div>




<div *ngIf="me$ | async as me else loading">
    <mat-card style="max-width: 300px">



        <!-- ++++++++++++++++ -->
        <!-- not in edit mode -->
        <!-- ++++++++++++++++ -->
        <!-- Here goes the content of the card -->
        <div>
            <mat-card-header>
                <!-- Title: Name -->
                <mat-card-title>
                    {{ me.name }}
                </mat-card-title>

                <!-- Status - not editable -->
                <mat-card-subtitle> Status: {{ me.rights }} </mat-card-subtitle>


                <!--  Logout - Button -->
                <button mat-button (click)="callLogout()">
                    <mat-icon aria-hidden="false" aria-label="logout icon">logout</mat-icon>
                </button>
            </mat-card-header>

            <!-- Image -->
            <img mat-card-image src="{{me.picture}}">


            <mat-card-content>

                <!-- Show the data of the Person -->
                <app-user [person]="me"></app-user>
            </mat-card-content>

        </div>
    </mat-card>
</div>