import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-news-list-element',
  templateUrl: './news-list-element.component.html',
  styleUrls: ['./news-list-element.component.scss']
})
export class NewsListElementComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
