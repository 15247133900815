import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';


import { bl19sermonData, bl19personOwn, bl19singleQuery } from 'src/app/app.bl.types';
import { SermonService } from 'src/app/services/sermon.service';


@Component({
  selector: 'bl19-sermon-list',
  templateUrl: './sermon-list.component.html',
  styleUrls: ['./sermon-list.component.scss']
})
export class SermonListComponent implements OnInit {

  @Input() searchQuery?: bl19singleQuery[] = [];
  @Input() limit?: number;

  @Input() sermonSite?: boolean = false;

  @Input() sermonType?: string = null;

  @Output() sermonEmitter = new EventEmitter<bl19sermonData>();
  @Output() latestSermonEmitter = new EventEmitter<bl19sermonData>();

  initialQuery: bl19singleQuery[] = [];
  activeQuery: bl19singleQuery[] = [];

  sermonList: Array<bl19sermonData> = null;
  lastSermon: bl19sermonData = null;
  noMoreSermons: boolean = false;

  loadMoreSermonsLimit: number = 10;

  constructor(private serServ: SermonService,
  ) { }

  ngOnInit() {

    if (this.sermonType) {
      this.searchQuery.push({
        parameter: 'type',
        value: this.sermonType
      });
    }

    this.activeQuery = this.searchQuery;

    this.getSermons();
  }


  goToSermon(choosenSermon: bl19sermonData) {
    this.sermonEmitter.emit(choosenSermon);
    window.scroll(0, 0);
  }

  getSermons() {

    // empty the list before adding new data
    this.sermonList = [];

    this.serServ.getAllSermonsByQuery(this.activeQuery, this.limit).then(res => {
      if (res[0]) {
        this.sermonList = res;
        this.lastSermon = this.sermonList[this.sermonList.length - 1];

        if (res.length < this.limit) {
          this.noMoreSermons = true;
        }

        // emitt the most current sermon, so that allways a sermon will be displayed:
        this.latestSermonEmitter.emit(this.sermonList[0]);
      }
    }, err => this.sermonList = null);

  }

  loadMoreSermons() {
    this.serServ.getAllSermonsByQuery(this.activeQuery, this.limit, this.lastSermon.date).then(res => {

      res.forEach(element => {
        this.sermonList.push(element);
      });

      this.lastSermon = this.sermonList[this.sermonList.length - 1];

      if (res.length < this.limit) {
        this.noMoreSermons = true;
      }


    });
  }

  setQuery(newQuery: bl19singleQuery[]) {
    this.activeQuery = newQuery;

    this.noMoreSermons = false;

    this.getSermons();
  }

}
