import { Component, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';


import { bl19personOwn, bl19group } from 'src/app/app.bl.types';
import { AuthService } from 'src/app/services/auth.service';
import { GroupsService } from 'src/app/services/groups.service';


@Component({
  selector: 'bl19-groups',
  templateUrl: './groups.component.html',
  styleUrls: ['./groups.component.scss']
})
export class GroupsComponent implements OnInit {

  addGroup: boolean = false;

  subscriptionMe: Subscription;
  me$: bl19personOwn = null;


  groupList: Array<bl19group> = null;

  constructor(private groupServ: GroupsService,
    private authServ: AuthService) { }

  ngOnInit() {
    this.groupServ.getAllInternGroups().then(res => {
      this.groupList = res;
    },
      err => this.groupList = null);

    this.subscriptionMe = this.authServ.getMeObs().subscribe(user => {
      if (user) {
        this.me$ = user;
      }
    });
  }


  ngOnDestroy(): void {
    //Called once, before the instance is destroyed.
    //Add 'implements OnDestroy' to the class.
    if (this.subscriptionMe) {
      this.subscriptionMe.unsubscribe();
    }
  }

}
