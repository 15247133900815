<!-- Name -->
<div class="layoutRow">
    <h3>
        {{ person.name }}
    </h3>

    <!-- Show edit button only when current user is the same person,
    or if current User is admin -->
    <div *ngIf="(me.UID == person.UID) || (me.rights >= 1000)">
        <button mat-button (click)="setEdit()">
            <mat-icon aria-hidden="false" aria-label="edit icon">edit</mat-icon>
        </button>
    </div>
</div>


<!-- Rechte -->
<div *ngIf="person.rights">
    <p>
        Rechte: {{ person.rights }}
    </p>
</div>



<!-- Birthday -->
<div *ngIf="person.bDay">
    Geburtstag: {{ person.bDay | date:'d.M.yyyy' }}
</div>



<!-- email - can not be changed, because it is used for Login -->
<div *ngIf="person.email && me.rights>=1000">
    <div class="layoutRow">
        <mat-icon aria-hidden="false" aria-label="mail icon">email</mat-icon>
        <p>
            {{ person.email }}
        </p>
    </div>
</div>


<!-- phone -->
<div *ngIf="person.phone">
    <div class="layoutRow">
        <mat-icon aria-hidden="false" aria-label="phone icon">phone</mat-icon>
        <p>
            {{ person.phone }}
        </p>
    </div>
</div>


<!-- adress -->
<div *ngIf="person.street && person.postalCd && person.city">
    <div class="layoutRow">
        <mat-icon aria-hidden="false" aria-label="phone icon">map</mat-icon>
        <p>
            {{ person.street }} <br>
            {{ person.postalCd }} {{ person.city }}
        </p>
    </div>
</div>