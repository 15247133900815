<div class="layoutColumn" style="padding-top: 15%; padding-bottom: 15%; margin:auto">

    <mat-card style="max-width: 80%; margin: auto;">
        <form [formGroup]="editSermonForm">
            <div *ngIf="sermon" style="text-align: center;">
                <h1 *ngIf="sermon.suid">{{ sermon.suid }}</h1>
            </div>

            <div class="blEditSermon" style="text-align: center;">
                <mat-checkbox matInput formControlName="showOnWelcomePage" [checked]="editSermonData.showOnWelcomePage">
                    Auf Startseite anzeigen
                </mat-checkbox>
            </div>



            <div class="blEditSermon layoutColumn">
                <mat-form-field class="blEditSermon bl19inputFild">
                    <input matInput formControlName="title" [placeholder]="editSermonData.title">
                    <mat-hint>Titel</mat-hint>
                </mat-form-field>



                <mat-form-field class="blEditSermon">
                    <input matInput type="date" formControlName="date"
                        [placeholder]="editSermonData.date | date:'d.M.yyyy'">
                    <mat-hint>Datum</mat-hint>
                </mat-form-field>
            </div>


            <!-- ############## -->
            <!-- Place handling -->
            <div class="blEditSermon layoutRow">
                <mat-form-field class="blEditSermon">
                    <mat-label *ngIf="!sermon">Bibelstelle</mat-label>
                    <mat-label *ngIf="sermon">{{sermon.book}}</mat-label>
                    <mat-select formControlName="book">
                        <mat-option *ngFor="let book of bibleBooks" [value]="book">
                            {{ book }}
                        </mat-option>
                    </mat-select>
                </mat-form-field>

                <div>

                    <div class="layoutRow">
                        <mat-form-field class="blEditSermon" style="max-width: 60px;">
                            <input type="number" matInput formControlName="chStart"
                                [placeholder]="editSermonData.chapterStart" (ngModelChange)="changeChapter($event)">
                            <mat-hint>Kapitel</mat-hint>
                        </mat-form-field>
                        <mat-form-field class="blEditSermon" style="max-width: 60px;">
                            <input type="number" matInput formControlName="verseStart"
                                [placeholder]="editSermonData.verseStart">
                            <mat-hint>Vers</mat-hint>
                        </mat-form-field>
                    </div>


                    <div class="layoutRow">
                        <mat-form-field class="blEditSermon" style="max-width: 60px;">
                            <input type="number" matInput formControlName="chEnd"
                                [placeholder]="editSermonData.chapterEnd">
                            <mat-hint>bis Kapitel</mat-hint>
                        </mat-form-field>
                        <mat-form-field class="blEditSermon" style="max-width: 60px;">
                            <input type="number" matInput formControlName="verseEnd"
                                [placeholder]="editSermonData.verseEnd">
                            <mat-hint>bis Vers</mat-hint>
                        </mat-form-field>
                    </div>
                </div>
            </div>





            <!-- ################# -->
            <!-- Preacher Handling -->
            <div class="blEditSermon layoutRow">

                <mat-checkbox (change)="editSermonData.guestPreacher = $event.checked" formControlName="guestPrForm"
                    class="blEditSermon">
                    Gast Prediger
                </mat-checkbox>


                <div *ngIf="editSermonData.guestPreacher" class="blEditSermon">
                    <mat-form-field>
                        <input matInput formControlName="preacherName" [placeholder]="editSermonData.preacherName">
                        <mat-hint>Name</mat-hint>
                    </mat-form-field>
                </div>


                <div *ngIf="!editSermonData.guestPreacher" class="blEditSermon">
                    <mat-form-field>
                        <mat-label *ngIf="!editSermonData.preacherObject">Prediger</mat-label>
                        <mat-label *ngIf="editSermonData.preacherObject">{{editSermonData.preacherObject.name}}
                        </mat-label>
                        <mat-select formControlName="preacherObject">
                            <mat-option *ngFor="let preacher of preachers" [value]="preacher">
                                {{ preacher.name }}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
            </div>

            <div class="layoutRow">
                <mat-form-field>
                    <mat-label>Typ der Predigt:</mat-label>
                    <mat-select formControlName="type">
                        <mat-option *ngFor="let type of sermonTypes" [value]="type">
                            {{ type }}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>



            <mat-divider></mat-divider>

            <div>
                <mat-form-field class="bl19inputFild">
                    <input matInput formControlName="videoLink" [placeholder]="editSermonData.videoLink">
                    <mat-hint>YoutubeCode zum Video - bitte nur den Code angeben, der richtige link wird dann
                        automatisch erzeugt.</mat-hint>
                </mat-form-field>
            </div>

            <div *ngIf="sermon && sermon.suid && !sermon.link">
                <button (click)="dialogUploadFile()" mat-button style="margin: 30px;">
                    <mat-icon aria-hidden="false" aria-label="upload icon">cloud_upload</mat-icon>
                    Upload mp3
                </button>
                <!-- bl19-upload-sermon [suid]="sermon.suid" (successEmitter)="downloadUrlReady($event)"></bl19-upload-sermon -->
            </div>
            <div *ngIf="sermon && sermon.link" class="layoutRow">
                <audio controls style="margin: 30px;">
                    <source src="{{ sermon.link }}" type="audio/mpeg">
                    Your browser does not support the audio element.
                </audio>
                <div *ngIf="sermon.fileSize">
                    <p>
                        File size in Bytes: {{sermon.fileSize}}
                    </p>
                </div>
            </div>



            <div *ngIf="sermon && sermon.suid" style="margin: auto">
                <button (click)="dialogUploadPhoto()" mat-button style="margin: 30px;">
                    <mat-icon aria-hidden="false" aria-label="upload icon">cloud_upload</mat-icon>
                    Upload Photo
                </button>
            </div>
            <div *ngIf="sermon && sermon.picture" style="margin: auto">
                <img src="{{ sermon.picture }}" alt="Sermon picture">
            </div>





            <mat-divider></mat-divider>

            <!--  Save and Undo -->
            <div class="layoutRow" style="padding: 20px">
                <button (click)="editSermon()" mat-button *ngIf="changesMade">
                    <mat-icon aria-hidden="false" aria-label="done icon">done</mat-icon>
                    Speichern
                </button>
                <button (click)="deleteSermon()" mat-button type="button">
                    <mat-icon aria-hidden="false" aria-label="delete icon">delete</mat-icon>
                    Löschen
                </button>
                <button (click)="closeEdit()" mat-button type="button">
                    <mat-icon aria-hidden="false" aria-label="delete icon">clear</mat-icon>
                    Schließen
                </button>
            </div>

        </form>
    </mat-card>

</div>