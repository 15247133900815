import { Component, OnInit, ViewChild, Inject, ElementRef, Input } from '@angular/core';

import { MAT_DIALOG_DATA as MAT_DIALOG_DATA, MatDialogRef as MatDialogRef } from "@angular/material/dialog";
import { MatGridTile } from "@angular/material/grid-list";

import { Observable } from 'rxjs';
import { AngularFireUploadTask, AngularFireStorage } from '@angular/fire/compat/storage';
import { finalize } from 'rxjs/operators';




@Component({
  selector: 'bl19-upload-photo',
  templateUrl: './upload-photo.component.html',
  styleUrls: ['./upload-photo.component.scss']
})
export class UploadPhotoComponent implements OnInit {


  public fileChoosen: boolean = false;
  public choosenFileUrl: string;



  // +++++++++++++++++++++++++++++++++
  // For File Upload:

  @ViewChild('fileInput', { static: false }) fileInput


  // Main task
  task: AngularFireUploadTask;

  // Progress monitoring
  percentage: Observable<number>;

  snapshot: Observable<any>;

  downloadURL: string = '';
  fileName: string = "";

  // state for dropzone
  // need to show hovering effect
  isHovering: boolean



  constructor(private dialogRef: MatDialogRef<UploadPhotoComponent>,
    private storage: AngularFireStorage,
    @Inject(MAT_DIALOG_DATA) public data: any) { }


  ngOnInit() {
  }


  startCropping(fileList: FileList) {
    const file = fileList[0];

    var reader = new FileReader();
    reader.onload = () => {
      this.choosenFileUrl = reader.result as string;
    };
    reader.readAsDataURL(file)


    this.fileChoosen = true;
  }


  startUpload(file: File) {

    // where to put the file:
    const path = this.data.path + '_cmprsNeeded_' + file.name;
    console.log(path);

    // Main task:
    this.task = this.storage.upload(path, file);

    // to get the downloadpath later on
    const ref = this.storage.ref(path);


    // progress:
    this.percentage = this.task.percentageChanges();
    this.snapshot = this.task.snapshotChanges();


    // the files download URL
    this.task.snapshotChanges().pipe(
      finalize(() => {
        ref.getDownloadURL().toPromise().then(res => {
          this.downloadURL = res;

          // comunicate to parent component that upload was successfull
          // and close the dialog
          const ret = { url: this.downloadURL, file: path }
          this.dialogRef.close(ret);

        }, err => {
          console.error('uploadSermonComponent: error receive the download URL');

          // Close the dialog, even if it was not successfull on receiving the download URL
          // 
          const ret = { url: this.downloadURL, file: path }
          this.dialogRef.close(ret);
        });
      })
      // no need to unsubscribe, because of finalize
    ).subscribe();
  }




  toggleHover(event: boolean) {
    this.isHovering = event;
  }


  // Determines if the upload task is active
  isActive(snapshot) {
    return snapshot.state === 'running' && snapshot.bytesTransferred < snapshot.totalBytes
  }

  triggerFileInput() {
    this.fileInput.nativeElement.click();
  }


  close() {
    this.dialogRef.close();
  }


}
