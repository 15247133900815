import { Injectable } from '@angular/core';

import { AngularFirestore, AngularFirestoreCollection } from '@angular/fire/compat/firestore'

import { bl19Leader } from '../../app/app.bl.types';



@Injectable({
  providedIn: 'root'
})
export class WebDataService {

  private _leadersStore: bl19Leader[] = [];
  private _refLeadersCollection: AngularFirestoreCollection<bl19Leader>;

  constructor(afs: AngularFirestore) {
    this._refLeadersCollection = afs.collection<bl19Leader>('web/team/leaders');
  }



  // return the leadership team members, loads them from database if not yet available
  getLeadershipTeam() {
    return new Promise<bl19Leader[]>((resolve, reject) => {

      // if store is empty -> load the data from database
      // afterwards send it to the caller
      if (!this._leadersStore.length) {

        // debug
        console.log('download data from firestore...');

        // load the data - once - as a promise
        this._refLeadersCollection.get().toPromise().then(res => {
          res.docs.forEach(doc => {
            let newElement: bl19Leader = {
              //todo: Object name should be id: doc.id
              "name": doc.data().name,
              "role": doc.data().role,
              "picture": doc.data().picture
            }
            this._leadersStore.push(newElement);
          });

          // download successful
          // now return the data to caller
          resolve(this._leadersStore);

        }, err => {
          // download was not sucessfull
          // log the error
          // and return error to caller
          console.log("bl19leaders download failed: " + err);
          reject(err);
        });

      } else {

        // debug
        console.log('return saved leadership data...')

        // if store already contains the data, just return it to caller
        resolve(this._leadersStore);
      }
    });
  }






}
