<form [formGroup]="editGroupForm">

    <div style="layoutColumn">
        <mat-form-field class="blEditGroup">
            <input matInput formControlName="name" [placeholder]="editGroupData.name">
            <mat-hint>Name der Gruppe</mat-hint>
        </mat-form-field>


        <mat-form-field class="blEditGroup">
            <input matInput formControlName="description" [placeholder]="editGroupData.description">
            <mat-hint>Beschreibung:</mat-hint>
        </mat-form-field>
    </div>


    <!--  Save and Undo -->
    <div style="padding: 20px" class="blEditGroup layoutRow">
        <button (click)="editGroup()" mat-button>
            <mat-icon aria-hidden="false" aria-label="done icon">done</mat-icon>
            Speichern
        </button>
        <button (click)="closeEdit()" mat-button type="button">
            <mat-icon aria-hidden="false" aria-label="delete icon">delete</mat-icon>
            Abbrechen
        </button>
    </div>
</form>