<form [formGroup]="updatePersonForm">

    <!--  Save and Undo -->
    <div class="layoutRow" style="padding-bottom: 20px">
        <button (click)="updatePerson()" mat-button>
            <mat-icon aria-hidden="false" aria-label="done icon">done</mat-icon>
            Speichern
        </button>
        <button (click)="cancelEdit()" mat-button type="button">
            <mat-icon aria-hidden="false" aria-label="delete icon">delete</mat-icon>
            Abbrechen
        </button>
    </div>


    <mat-form-field class="blEditPerson">
        <input matInput formControlName="name" [placeholder]="person.name">
        <mat-hint>Name</mat-hint>
    </mat-form-field>



    <mat-form-field class="blEditPerson">
        <input matInput type="date" formControlName="bDay" [placeholder]="person.bDay | date:'d.M.yyyy' ">
        <mat-hint>Geburtstag</mat-hint>
    </mat-form-field>


    <mat-form-field class="blEditPerson">
        <input matInput formControlName="phone" [placeholder]="person.phone">
        <mat-hint>Telefonnummer</mat-hint>
    </mat-form-field>


    <!-- Adress -->
    <div class="blEditPerson">
        <mat-form-field>
            <input matInput formControlName="street" [placeholder]="person.street">
            <mat-hint>Straße und Hausnummer</mat-hint>
        </mat-form-field>
        <mat-form-field>
            <input matInput formControlName="city" [placeholder]="person.city">
            <mat-hint>Stadt</mat-hint>
        </mat-form-field>
        <mat-form-field>
            <input matInput formControlName="postalCd" [placeholder]="person.postalCd">
            <mat-hint>Postleitzahl</mat-hint>
        </mat-form-field>
    </div>


    <div *ngIf="person && person.UID">
        <button (click)="dialogUploadPhoto()" mat-button style="margin: 30px;">
            <mat-icon aria-hidden="false" aria-label="upload icon">cloud_upload</mat-icon>
            Upload Photo
        </button>
    </div>
    <div *ngIf="person && person.picture">
        <img mat-card-image src="{{ person.picture }}" alt="profile picture">
    </div>

</form>