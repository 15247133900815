import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';

import {
  MatSnackBar as MatSnackBar,
  MatSnackBarHorizontalPosition as MatSnackBarHorizontalPosition,
  MatSnackBarVerticalPosition as MatSnackBarVerticalPosition,
} from '@angular/material/snack-bar';


import * as firebase from "firebase/app";
import '@firebase/firestore';

import { bl19event, bl19eventRegistration } from 'src/app/app.bl.types';

import { Observable, Subscription, BehaviorSubject } from 'rxjs';




@Injectable({
  providedIn: 'root'
})
export class EventsService {

  horizontalPosition: MatSnackBarHorizontalPosition = 'center';
  verticalPosition: MatSnackBarVerticalPosition = 'top';

  subWatchingSingleEvent$: Subscription;
  watchingSingleEvent = new BehaviorSubject<bl19event>({});


  constructor(
    private afs: AngularFirestore,
    private _snackBar: MatSnackBar
  ) { }


  openOKSnackBar(msgText: string) {
    this._snackBar.open(msgText, 'OK', {
      duration: 3000,
      horizontalPosition: this.horizontalPosition,
      verticalPosition: this.verticalPosition,
    });
  }

  watchSingleEvent(id: string): Observable<bl19event> {
    if (this.watchingSingleEvent.value.ID == id) {
      return this.watchingSingleEvent.asObservable();
    } else {
      // ToDo: unsubscribe has to be done on closing of the dialog...
      // this is only a work a round...
      // maybe create a function in this service, that will unsubscribe and call it on closing of the dialog

      const refEventDoc = this.afs.doc<any>('web/content/events/' + id);
      this.subWatchingSingleEvent$ = refEventDoc.valueChanges().subscribe(val => {
        let eventWithDate: bl19event = val;
        eventWithDate.date = val.date.toDate();
        this.watchingSingleEvent.next(eventWithDate);
      });
      return this.watchingSingleEvent.asObservable();
    }
  }


  getAvailableEvents(startAfter?: Date): Promise<bl19event[]> {
    return new Promise<any>((resolve, reject) => {

      let availableEvents: Array<bl19event> = []

      if (!startAfter) {
        let yesterday = new Date();
        yesterday.setDate(yesterday.getDate() - 1);
        startAfter = yesterday;
      }


      let colEv = this.afs.collection<any>('web/content/events').ref
        .orderBy('date', 'asc')
        .where('date', '>=', startAfter);


      colEv.get().then(res => {
        res.docs.forEach(doc => {

          // change from timestamp to JS Date-Object
          let element = doc.data();
          element.date = doc.data().date.toDate();
          availableEvents.push(element);

          resolve(availableEvents);
        });
      }, err => {
        reject(err);
        console.error("Error on downloading available events: " + err);
      });

    });

  }


  updateEvent(event: bl19event, id?: string): Promise<any> {
    return new Promise<any>((resolve, reject) => {

      if (id) {
        const docEvent = this.afs.doc<bl19event>('web/content/events/' + id);

        docEvent.update(event).then(res => {
          console.log('EventsService: successfully updated event: ' + id);
          resolve(res);
        }, err => {
          console.error('EventService: Error on updating Event with id: ' + id);
          reject(err);
        });
      } else {
        const collEvents = this.afs.collection<bl19event>('web/content/events');
        collEvents.add(event).then(res => {
          console.log('EventService: Successfully added new EventData: ' + res.id);

          // now download the new uploaded doc:
          const docEvent = this.afs.doc<bl19event>('web/content/events/' + res.id);
          docEvent.get().toPromise().then(res => {
            let result: bl19event = res.data();

            // add the id as ID and then send this back to the caller
            result.ID = res.id;


            docEvent.update(result).then(res => {
              console.log('EventService: Successfully update ID on the most recent added Event: ' + result.ID);
            }, err => {
              console.error('EventsService: Error on update ID on the most recent added Event: ' + result.ID);
            });

            resolve(result);
          }, err => {
            console.error('EventService: Error on downloading most recent added EventData: ' + err);
          });
        }, err => {
          console.error('EventService: Error on adding new EventData: ' + err);
          reject(err);
        });
      }

    });
  }



  updateRegistration(uid: string, registration: bl19eventRegistration, id?: string): Promise<any> {
    return new Promise<any>((resolve, reject) => {

      if (id) {
        const docEvent = this.afs.doc<bl19eventRegistration>('users/u_default_groups/own_data/' + uid + '/eventRegistrations/' + id);

        docEvent.update(registration).then(res => {
          console.log('EventsService: successfully updated Registration: ' + id);

          this.openOKSnackBar('Deine Änderungen wurden erfolgreich übertragen.');

          resolve(res);
        }, err => {
          console.error('EventService: Error on updating Registration with id: ' + id);
          reject(err);
        });
      } else {
        const collEvents = this.afs.collection<bl19eventRegistration>('users/u_default_groups/own_data/' + uid + '/eventRegistrations/');
        collEvents.add(registration).then(res => {
          console.log('EventService: Successfully added new Registration: ' + res.id);

          this.openOKSnackBar('Anmeldung wurde übertragen, du kannst in wenigen Augenblicken unten sehen ob es erfolgreich war.');

          resolve(res);
        }, err => {
          console.error('EventService: Error on ading Registration Data: ' + err);
        });
      }
    });
  }


  deleteEvent(id: string): Promise<any> {
    return new Promise<any>((resolve, reject) => {

      const refEventDoc = this.afs.doc<bl19event>('web/content/events/' + id);

      refEventDoc.delete().then(res => {
        console.log("Event data deleted, id: " + id);
      }, err => {
        console.error("Error on deleting Event Data: " + err);
      });
    });
  }



  getPlannedEvents(): Promise<any> {
    return new Promise<any>((resolve, reject) => {

      const docEventsData = this.afs.doc<any>('web/content/events/eventData');

      docEventsData.get().toPromise().then(res => {

        let eventsRaw: any = res.data();

        if (eventsRaw.meta.count && eventsRaw.meta.count > 0) {
          eventsRaw.data.forEach(event => {

            // string from data ----> DATE-Object
            let date: Date = new Date(event.startDate);
            event.startDate = date;

          });
        }
        resolve(eventsRaw.data);

      }, err => {
        console.error("EventsService: Error on downloading events Data: " + err);
        reject(err);
      });
    });
  }



  getSingleEvent(id: string): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      let event: bl19event;

      const docEvent = this.afs.doc<bl19event>('web/content/events/' + id);

      docEvent.get().toPromise().then(res => {
        event = res.data();
        event.date = res.data().date;
        resolve(event);
      }, err => {
        console.error('EventsService: Error on downloading Single Event: ' + err);
        reject(err);
      });
    });
  }


  deleteRegistration(userID: string, id: string): Promise<any> {
    return new Promise<any>((resolve, reject) => {

      const refRegDoc = this.afs.doc<bl19eventRegistration>('users/u_default_groups/own_data/' + userID + '/eventRegistrations/' + id);

      refRegDoc.delete().then(res => {
        console.log("Registration data deleted, UID: " + id);
      }, err => {
        console.error("Error on deleting Registration Data: " + err);
      });
    });
  }


  getRegistrations(userID?: string, eventReg?: string, startAfter?: Date,): Promise<any> {
    return new Promise<any>((resolve, reject) => {

      if (!startAfter) {
        let yesterday = new Date();
        yesterday.setDate(yesterday.getDate() - 1);
        startAfter = yesterday;
      }

      let refRegDoc: any;

      if (!eventReg) {
        refRegDoc = this.afs.collection<any>('users/u_default_groups/own_data/' + userID + '/eventRegistrations/').ref
          .orderBy('eventDate', 'asc')
          .where('eventDate', '>=', startAfter);
      } else {
        refRegDoc = this.afs.collection<any>('web/content/events/' + eventReg + '/registrations/').ref
          .orderBy('eventDate', 'asc')
          .where('eventDate', '>=', startAfter);
      }



      let regArray: Array<bl19eventRegistration> = [];

      refRegDoc.get().then(res => {

        res.docs.forEach(doc => {

          let withTime: bl19eventRegistration = doc.data();

          // change from the firebase timestamp to javaScript DATE object
          let date: Date = doc.data().eventDate.toDate();

          withTime.eventDate = date;

          regArray.push(withTime);
        });

        resolve(regArray);

      }, err => {
        console.error("Error on deleting Registration Data: " + err);
        reject(err);
      });
    });
  }

}
