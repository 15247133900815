import { Component, OnInit, Input } from '@angular/core';
import { trigger, transition, style, animate } from "@angular/animations";

import { Subscription, timer } from 'rxjs';

import { bl19news, bl19singleQuery } from 'src/app/app.bl.types';

import { NewsService } from 'src/app/services/news.service';


@Component({
  selector: 'bl19-news-carousel',
  templateUrl: './news-carousel.component.html',
  styleUrls: ['./news-carousel.component.scss'],
  animations: [
    trigger('carouselAnimation', [
      transition('void => *', [
        style({ opacity: 0 }),
        animate('1000ms', style({ opacity: 1 }))
      ]),
      transition('* => void', [
        animate('1000ms', style({ opacity: 0 }))
      ])
    ])
  ]
})
export class NewsCarouselComponent implements OnInit {

  @Input() maxNews?: number = 5;
  @Input() inQueryNews?: bl19singleQuery = {
    parameter: 'showOnWelcomePage',
    value: true
  }


  queryNews: bl19singleQuery[] = [
  ];

  newsList: Array<bl19news> = [];
  lastNews: bl19news = null;

  blankNews: bl19news = {
    heading: 'Willkommen',
    picture: '../../../../assets/pictures/bible3.jpg',
  };


  // for parameters in calling url
  subParam: Subscription;

  currentNews: number = 0;



  // timer for changing news
  timing: number = 10000;
  subCntdwn: Subscription;

  subCurrentTime: Subscription;
  currentTime$: number = 0;

  runningTimer: Boolean = true;



  constructor(private newsServ: NewsService) { }

  ngOnInit(): void {

    this.queryNews.push(this.inQueryNews);

    this.newsServ.getAllNewsByQuery(this.queryNews, this.maxNews).then(res => {
      if (res[0]) {
        this.newsList = res;
        this.lastNews = this.newsList[this.newsList.length - 1];
      } else {
        this.newsList.push(this.blankNews);
      };
    }, err => this.newsList.push(this.blankNews));

    this.subscribeNewCntdwnSub();

    this.subCurrentTime = timer(100, 100)
      .subscribe(x => { this.currentTime$ = this.currentTime$ + ((100 / this.timing) * 100) })
  }


  subscribeNewCntdwnSub() {
    this.subCntdwn = timer(this.timing, this.timing)
      .subscribe((val) => { this.onNextClick() });
  }

  unsubscribeCntdwnSub() {
    if (this.subCntdwn) {
      this.subCntdwn.unsubscribe();
    }
  }

  ngOnDestroy(): void {
    //Called once, before the instance is destroyed.
    //Add 'implements OnDestroy' to the class.

    if (this.subCurrentTime) {
      this.subCurrentTime.unsubscribe();
    }

    this.unsubscribeCntdwnSub();
  }

  onPreviousClick() {
    // reset current time because of new slide
    this.currentTime$ = 0

    this.unsubscribeCntdwnSub();
    this.subscribeNewCntdwnSub();

    const previousNews = this.currentNews - 1;
    this.currentNews = previousNews < 0 ? this.newsList.length - 1 : previousNews;
  }

  onNextClick() {
    // reset current time because of new slide
    this.currentTime$ = 0

    this.unsubscribeCntdwnSub();
    this.subscribeNewCntdwnSub();

    const nextNews = this.currentNews + 1;
    this.currentNews = nextNews === this.newsList.length ? 0 : nextNews;
  }

  chooseNews(idx: number) {

    // reset current time because of new slide
    this.currentTime$ = 0

    this.unsubscribeCntdwnSub();
    this.subscribeNewCntdwnSub();

    // change to requested news
    this.currentNews = idx;
  }

  useLink(url: string) {
    window.location.href = url;
  }
}
