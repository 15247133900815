import { Component, OnInit } from '@angular/core';

import { Router, ActivatedRoute } from '@angular/router';
import { UntypedFormControl } from '@angular/forms';


@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  retUrl: string;

  selected = new UntypedFormControl(0);

  seeForgotPW: boolean = true;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
  ) { }

  ngOnInit() {
    this.retUrl = this.route.snapshot.queryParams['retUrl'] || '/home';
  }

  onLoginCompleted() {
    this.router.navigate([this.retUrl]);
  }

}
