<div class="backgroundPic" [ngStyle]="{'background-image': getBackgroundURL()}">

    <div class="layoutRow" style="padding-top: 15%; padding-bottom: 15%;">
        <mat-card style="max-width: 80%;">

            <div class="layoutRowSpaceBetween">

                <div>
                    <h3>{{ sermon.type }} {{ sermon.date | date }}</h3>
                    <h3>{{ sermon.preacherName }}</h3>
                </div>

                <div>
                    <img *ngIf="sermon.preacherObject && sermon.preacherObject.thumb_picture"
                    src={{sermon.preacherObject.thumb_picture}} alt="Bild zur Predigt"
                    style="height: 50px; border-radius: 50%;">
                </div>

            </div>



            <div>
                <div>
                    <h1>{{ sermon.title }}</h1>
                    <h1 style="margin-top: 0px;">{{ sermon.book }} {{ chapterVerse }}</h1>
                </div>


                <!-- Show edit button only when current user is is content manager -->
                <div *ngIf="(me$ && (me$.rights >= 500))">
                    <button mat-button (click)="setEdit()">
                        <mat-icon aria-hidden="false" aria-label="edit icon">edit</mat-icon>
                    </button>
                </div>
            </div>




            <div style="text-align: center;">

                <mat-tab-group mat-stretch-tabs dynamicHeight *ngIf="sermon.link && sermon.videoLink">

                    <mat-tab label="Audio">
                        <audio controls style="margin-top: 20px; z-index: 0;">
                            <source src="{{ sermon.link }}" type="audio/mpeg">
                            Your browser does not support the audio element.
                        </audio>
                    </mat-tab>

                    <mat-tab label="Video">
                        <iframe width="{{activeRatio.width}}" height="{{activeRatio.height}}"
                            [src]="sermon.videoLink | safe" frameborder="0"
                            allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                            allowfullscreen style="margin-top: 20px;">
                        </iframe>
                    </mat-tab>

                </mat-tab-group>

                <div *ngIf="sermon.link && !sermon.videoLink">
                    <audio controls style="z-index: 0;">
                        <source src="{{ sermon.link }}" type="audio/mpeg">
                        Your browser does not support the audio element.
                    </audio>
                </div>

                <div *ngIf="sermon.videoLink && !sermon.link">
                    <iframe width="{{activeRatio.width}}" height="{{activeRatio.height}}"
                        [src]="sermon.videoLink | safe" frameborder="0"
                        allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen>
                    </iframe>
                </div>
            </div>
        </mat-card>
    </div>



</div>