

<bl19-toolbar [transparent]="true"></bl19-toolbar>


<bl19-news-carousel [inQueryNews]="{parameter:'showOnWelcomePage',value:true}" [maxNews]="5"></bl19-news-carousel>



<!-- Sermon landingPageList -->
<div style="padding-top: 10%; padding-left: 10%; padding-right: 10%;">
    <div style="text-align: center">
        <h1>Reinhören:</h1>
    </div>

    <div>
        <bl19-sermon-list [searchQuery]='queries' [limit]='3'></bl19-sermon-list>
    </div>
</div>



<bl19-events></bl19-events>


<div style="padding-top: 10%; padding-bottom: 10%;">
    <div style="text-align: center">
        <h1>Dabei sein:</h1>
    </div>

    <bl19-news-list [inNewsQuery]='{parameter:"regularEvents", value:true }' [numberOfNews]=15></bl19-news-list>

    <!--bl19-news-carousel [inQueryNews]="{parameter:'regularEvents',value:true}" [maxNews]="5"></bl19-news-carousel-->
</div>

