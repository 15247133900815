<div *ngIf="sermonSite" style="margin-top: 30px; margin-bottom: 20px;">
    <bl19-query (queryEmitter)="setQuery($event)"></bl19-query>
</div>


<div *ngIf="!sermonList" class="blcenterelement">
    <mat-spinner></mat-spinner>
</div>

<div class="container">
    <mat-card class="blitem" *ngFor="let sermon of sermonList">
        <div class="blListElement" (click)="goToSermon(sermon)" [routerLink]="'/sermon'"
            [queryParams]="{suid: sermon.suid}">
            <bl19-sermon-list-element [sermon]="sermon"></bl19-sermon-list-element>
        </div>
    </mat-card>
</div>

<div *ngIf="!sermonSite" class="layoutRow" style="padding-top: 30px;">
    <button mat-fab color="primary" routerLink="/sermon">
        <mat-icon aria-label="Play Sermon Symbol">
            play_arrow
        </mat-icon>
    </button>
</div>

<div *ngIf="sermonSite && !noMoreSermons" class="layoutRow" style="padding-top: 30px;">
    <button mat-button (click)="loadMoreSermons()" color="primary" style="width: 100%;">
        <mat-icon aria-label="more Sermons Symbol">
            keyboard_arrow_down
        </mat-icon>
    </button>
</div>