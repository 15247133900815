import { Injectable } from '@angular/core';

import { AngularFirestore, AngularFirestoreCollection, AngularFirestoreDocument } from '@angular/fire/compat/firestore'
import { bl19sermonData, bl19personSmall, bl19singleQuery } from '../app.bl.types';


@Injectable({
  providedIn: 'root'
})

export class SermonService {

  AllPreachers: Array<bl19personSmall> = [];


  constructor(
    private afs: AngularFirestore,
  ) { }


  getAllSermonsByQuery(queryList?: bl19singleQuery[], limit?: number, startAfter?: Date): Promise<Array<bl19sermonData> | any> {
    return new Promise<Array<bl19sermonData>>((resolve, reject) => {

      let sermonArray: Array<bl19sermonData> = [];

      let collSermon = this.afs.collection<bl19sermonData>('web/content/sermon').ref
        .limit(limit || 2).orderBy('date', 'desc');

      if (queryList) {
        queryList.forEach(elem => {
          collSermon = collSermon.where(elem.parameter, '==', elem.value);
        });
      }

      if (startAfter) {
        collSermon = collSermon.startAfter(startAfter);
      }

      collSermon.get().then(res => {
        res.docs.forEach(doc => {
          sermonArray.push(doc.data());
        });
        resolve(sermonArray);
      }, err => {
        console.error('SermonService: Error on downloading All Sermons: ' + err);
        reject(err);
      });
    });
  }


  getSingleSermon(suid: string): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      let sermon: bl19sermonData;

      const docSermon = this.afs.doc<bl19sermonData>('web/content/sermon/' + suid);

      docSermon.get().toPromise().then(res => {
        sermon = res.data();
        resolve(sermon);
      }, err => {
        console.error('SermonService: Error on downloading Single Sermon: ' + err);
        reject(err);
      });
    });
  }

  updateSermon(sermon: bl19sermonData, suid?: string): Promise<any> {
    return new Promise<any>((resolve, reject) => {

      // if there is a sermon unique Identifier
      // we have to update an existing sermon
      if (suid) {
        const docSermon = this.afs.doc<bl19sermonData>('web/content/sermon/' + suid);
        docSermon.update(sermon).then(res => {
          console.log('SermonService: successfully updated Sermon: ' + suid);
          resolve(res);
        }, err => {
          console.error('SermonService: Error on updating Sermon with SUID: ' + suid + ' error: ' + err);
          reject(err);
        });
      } else {
        // if ther is no sermon unique Identifier
        // we have to create a new sermon
        const collSermon = this.afs.collection<bl19sermonData>('web/content/sermon');
        collSermon.add(sermon).then(res => {
          console.log('SermonService: Successfully added new SermonData: ' + res.id);

          // now download the new uploaded doc:
          const docSermon = this.afs.doc<bl19sermonData>('web/content/sermon/' + res.id);
          docSermon.get().toPromise().then(res => {
            let result: bl19sermonData = res.data();

            // add the id as sui and then send this back to the caller
            result.suid = res.id;
            resolve(result);
          }, err => {
            console.error('SermonService: Error on downloading most recent added SermonData: ' + err);
          });
        }, err => {
          console.error('SermonService: Error on adding new SermonData: ' + err);
          reject(err);
        });
      }
    });
  }


  getPreachers(): Promise<Array<bl19personSmall>> {
    return new Promise<any>((resolve, reject) => {
      if (this.AllPreachers.length < 1) {
        const collPreachers = this.afs.collection<bl19personSmall>('users/u_groups/intern/d5GivhGyN6xmsTmy9cuo/user');
        collPreachers.get().toPromise().then(res => {
          if (res.docs.length > 0) {
            res.docs.forEach(doc => {
              this.AllPreachers.push(doc.data());
            });
            resolve(this.AllPreachers);

          } else {
            console.error('SermonService: Error on downloading All Preachers - seems there is no data in this path ');
          }
        }, err => {
          console.error('SermonService: Error on downloading All Preachers: ' + err);
          reject('SermonService: Error on downloading All Preachers: ' + err);
        });
      } else {
        resolve(this.AllPreachers);
      }
    });
  }


  deleteSermon(uid: string): Promise<any> {
    return new Promise<any>((resolve, reject) => {

      const refNewsDoc = this.afs.doc<bl19sermonData>('web/content/sermon/' + uid);

      refNewsDoc.delete().then(res => {
        console.log("Sermon data deleted, UID: " + uid);
      }, err => {
        console.error("Error on deleting Sermon Data: " + err);
      });
    });
  }


}
