import { Component, OnInit, Inject } from '@angular/core';

import { Subscription } from 'rxjs';

import { MAT_DIALOG_DATA as MAT_DIALOG_DATA, MatDialogRef as MatDialogRef } from "@angular/material/dialog";

import { UsersService } from 'src/app/services/users.service';
import { bl19personOwn } from '../../../app.bl.types';


@Component({
  selector: 'bl19-add-from-person-own',
  templateUrl: './add-from-person-own.component.html',
  styleUrls: ['./add-from-person-own.component.scss']
})
export class AddFromPersonOwnComponent implements OnInit {

  membersSub: Subscription;
  members: Array<bl19personOwn> = null;
  selectedMembers: Array<bl19personOwn> = [];

  constructor(private usersServ: UsersService,
    private dialogRef: MatDialogRef<AddFromPersonOwnComponent>) { }


  ngOnInit() {

    this.membersSub = this.usersServ.getListsObs('membersFriendsFull').subscribe(members => {
      this.members = members;
    });
  }

  ngOnDestroy(): void {
    //Called once, before the instance is destroyed.
    //Add 'implements OnDestroy' to the class.
    if (this.membersSub) {
      this.membersSub.unsubscribe();
    }
  }


  addMember(newMember: bl19personOwn) {
    this.selectedMembers.push(newMember);
    this.dialogRef.close(this.selectedMembers);
  }

  save() {
    this.dialogRef.close(this.selectedMembers);
  }

  close() {
    this.dialogRef.close();
  }

}
