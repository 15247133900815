<form [formGroup]="editQuery">

    <div  class="blEditSermon" class="layoutRow">

        <mat-form-field class="blEdit">
            <mat-label>Art der Predigt</mat-label>
            <mat-select formControlName="sermonType">
                <mat-option *ngFor="let type of sermonTypes" [value]="type">
                    {{ type }}
                </mat-option>
            </mat-select>
        </mat-form-field>


        <mat-form-field class="blEdit">
            <mat-label>Bibelbuch</mat-label>
            <mat-select formControlName="bibleBook">
                <mat-option *ngFor="let book of bibleBooks" [value]="book">
                    {{ book }}
                </mat-option>
            </mat-select>
        </mat-form-field>


        <mat-form-field class="blEdit">
            <mat-label>Prediger</mat-label>
            <mat-select formControlName="preacherName">
                <mat-option *ngFor="let name of preachers" [value]="name">
                    {{ name }}
                </mat-option>
            </mat-select>
        </mat-form-field>

        <button mat-button class="blEdit" (click)="activateQuery()">
            <mat-icon aria-label="search symbol">
                search
            </mat-icon>
        </button>

    </div>

</form>