import { Component, OnInit } from '@angular/core';

import { bl19Leader } from '../../../app.bl.types';

import { WebDataService } from 'src/app/services/web-data.service'


@Component({
  selector: 'bl19-leaders',
  templateUrl: './leaders.component.html',
  styleUrls: ['./leaders.component.scss']
})
export class LeadersComponent implements OnInit {

  leaders: bl19Leader[] = [];

  constructor(private wds: WebDataService) { }

  ngOnInit() {
    this.wds.getLeadershipTeam().then(res => {
      this.leaders = res;
    }, err => {
      console.log('service did not return data: ');
      console.log(err);
    });
  }

}
