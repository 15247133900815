import { Injectable } from '@angular/core';
import {
  Router,
  Event,
  NavigationCancel,
  NavigationEnd,
  NavigationError,
  NavigationStart
} from '@angular/router';
import { BehaviorSubject, Observable } from 'rxjs';


@Injectable({
  providedIn: 'root'
})

export class UiServiceService {

  private loading = new BehaviorSubject<boolean>(false);

  constructor(private router: Router) {

    this.router.events.subscribe((event: Event) => {
      switch (true) {
        case event instanceof NavigationStart: {
          this.loading.next(true);
          break;
        }

        case event instanceof NavigationEnd:
        case event instanceof NavigationCancel:
        case event instanceof NavigationError: {
          this.loading.next(false);
          break;
        }

        default:
          break;
      }
    });
  }

  getLoadingObs(): Observable<boolean> {
    return this.loading.asObservable();
  }
}
