// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  firebase: {
    apiKey: "AIzaSyDQBBrqy1pHzul1qiAEeIm8ICdS0D-r6zE",
    authDomain: "bibel-leben-dev.firebaseapp.com",
    databaseURL: "https://bibel-leben-dev.firebaseio.com",
    projectId: "bibel-leben-dev",
    storageBucket: "bibel-leben-dev.appspot.com",
    messagingSenderId: "158462446409",
    appId: "1:158462446409:web:fd2a1d7d9786a657e02898",
    measurementId: "G-TWPT5DZY40"
  }
};

export const tinifyData = {
  apiKey: "KFjkHjjsBP7kLPn8XVKgHRSwcYCpn6mK"
}

export const CT_loginToken = "zxv69Ez3CcHk9u0nKZAX1iQTFNySDiIUdDPo6CNPHPawWbmVZmAd55uG5R05BAlwDAxmiCdVcEUg4kDIR265zQW4z6AixxuSfMdGH4XgwdmMA7GeLp6Ftp3B9LpGShW9TVgDpu1H5Wp0H0f7dJjw8TPDbU8UO9bIUB1PDCULjCwMVohqwSS29AHFhaulpbMIPNAEEHY8uyIin6RXcpKol391ediPX8SmeMNeAado9jzzFDaukcFtBEhPbfNvWKN5"


// Agora app ID: used for livestreaming
export const agoraAppId = '91939fc4972e4708a8651f5920b970dc';
export const agoraCertificate = null;



// if you want to create a RSS-Feed:
export const rssHeaderJs = {
  "_declaration": {
    "_attributes": {
      "version": "1.0",
      "encoding": "utf-8",
    },
  },
  "rss": {
    "_attributes": {
      "version": "2.0",
      "xmlns:itunes": "http://www.itunes.com/dtds/podcast-1.0.dtd",
      "xmlns:googleplay": "http://www.google.com/schemas/play-podcasts/1.0",
    },
    "channel": {
      "title": {
        "_text": "Bibelgemeinde Günzburg",
      },
      "itunes:author": {
        "_text": "Bibelgemeinde Günzburg",
      },
      "itunes:owner": {
        "itunes:name": {
          "_text": "Bibelgemeinde Günzburg",
        },
        "itunes:email": {
          "_text": "info@bibel-leben.de",
        },
      },
      "itunes:category": {
        "_attributes": {
          "text": "Religion & Spirituality"
        },
        "itunes:category": {
          "_attributes": {
            "text": "Christianity"
          },
        },
      },
      "description": {
        "_text": "Predigten der Bibelgemeinde Günzburg",
      },
      "itunes:image": {
        "_attributes": {
          "href": "https://firebasestorage.googleapis.com/v0/b/bibel-leben-dev.appspot.com/o/assets%2Flogobl.png?alt=media&token=d9ca7a60-92de-4051-a7f6-93db1c8364c2"
        },
      },
      "itunes:explicit": {
        "_text": "false",
      },
      "itunes:type": {
        "_text": "episodic",
      },
      "language": {
        "_text": "de-de",
      },
      "link": {
        "_text": "https://bibel-leben-dev.web.app/sermon",
      },
      "item": {} // empty items element
    },
  },
}

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
